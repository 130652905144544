@import url('https://fonts.googleapis.com/css2?family=Lato&family=Poppins:wght@400;700&display=swap');

*{
  font-family: 'Lato', sans-serif;
  font-size: 12pt;
  margin: 0;
  padding: 0;
  color: #2c2c2c;
  max-width: 100%;
}

p{
  margin-bottom: 1em;
}

.push-down{
  min-height: 100vh;
}

h1{
  font-family:  'Poppins', sans-serif;
  font-weight: bold;
  font-size: 14pt;
  color: #009FE3;
}
h2{
  display: inline-block;
  
}

.title-line{
  width: 20em;
}

.side-note{
  font-size: 10pt;
  margin-bottom: 1em;
}

input{
  color: #2c2c2c;
}

.name-input{
  width: 100%;
  padding: 1em;
  margin: 1em 0;
  outline: 0;
  transition: all .2s ease;
  border-width: 1px 1px 1px 1px;
  border-color: #e2e2e2;
  border-radius: 4px;
  border-style: solid;
  background-color: #ffffff;
  font-size: 14px;
  height: 3em;
  box-sizing: border-box;
  -webkit-box-sizing:border-box;
  -moz-box-sizing: border-box;
}

.checkbox-input{
  padding: 1em;
  margin: 1em 0;
  margin-right: 0.5em;
}

.radio-input{
  padding: 1em;
  margin: 1em;
}

button{
  float: left;
  width: auto;
  padding: 0 1em;
  margin: 1em 0;
  transition: all .2s ease;
  border-radius: 2em;
  border-style: none;
  background-color: #009FE3;
  font-family:  'Poppins', sans-serif;
  font-weight: bold;
  font-size: 14pt;
  color: #ffffff;
  height: 50px;
  text-transform: uppercase;
  transition: all .3s;
}

button:disabled,button:disabled:hover{
  background-color:#9c9ea1;
  color: #666666;
}

button:hover{
  background-color:#3d66ae;
}

li {
  list-style: inside;
}


.add-button{
  float: left;
  width: auto;
  padding: 0 0.5em;
  margin: 0.5em 0;
  transition: all .2s ease;
  border-radius: none;
  border-style: none;
  background: none;
  font-family:  'Poppins', sans-serif;
  font-weight: bold;
  font-size: 12pt;
  color: #009FE3;
  height: 30px;
  text-transform: uppercase;
  transition: all .3s;
}

.add-button:disabled,button:disabled:hover{
  background-color:#9c9ea1;
  color: #666666;
}

.add-button:hover{
  background-color:#3d66ae;
}

.neighbour_title_div{
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.neighbour_title{
  display: inline-flex;
  float: left;
  width: auto;
  padding: 0 0.5em;
  margin: 0.5em 0;
  font-weight: bold;
  font-size: 12pt;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
}

.neighbour-box{
  display: inline-block;
  border-radius: 1em;
  border-width: 1px;
  border-style: solid;
  border-color: #dadada;
  padding: 1em;
  margin-bottom: 1em;
}

.errorMsg{
  color: #f00;
  font-size: 10pt;
  margin: auto;
  padding: 0;
  position: static;
  top: 1em;
  z-index: 1;
}

.form-container {
  position: relative;
  display:inline-block;
  left: 20em;
  top:10em;
  min-width: 20em;
  padding: 2em;
  margin-bottom: 20em;
  background-color: #ffffff;
  border-radius: 8px 8px 8px 8px;
  overflow: hidden;
  box-shadow: 0px 10px 14px 9px rgb(0 0 0 / 10%);
}

.form-column{
  width: 20em;
  float: left;
  padding-right: 2em;
}

body{
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  background-image: url("../Img/Background-1200x800.jpg");
}

html{
  width: 100%;
}

@media screen and (max-width: 1500px) {
  .form-container {
    width: 100vw;
    left: 0;
    min-width: 0;
    padding: 2em;
  }
}

@media screen and (max-width: 1000px) {
  .form-container {
    position: relative;
    display:inline-block;
    width: 100vw;
    left: 0;
    top:0;
    min-width: 0;
    padding: 2em;
    margin-bottom: 20em;
    background-color: #ffffff;
    border-radius: 8px 8px 8px 8px;
    overflow: hidden;
    box-shadow: 0px 10px 14px 9px rgb(0 0 0 / 10%);
    box-sizing: border-box;
  }
  .form-column{
    width: 100%;
    float: left;
    padding-right: 0;
  }
}