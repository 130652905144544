.header{
    padding: 1em;
    position: fixed;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media screen and (max-width: 1000px) {
    .header{
      position: unset;
    }
  }
img{
    max-width: 90%;
}