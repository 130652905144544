@import url(https://fonts.googleapis.com/css2?family=Lato&family=Poppins:wght@400;700&display=swap);
*{
  font-family: 'Lato', sans-serif;
  font-size: 12pt;
  margin: 0;
  padding: 0;
  color: #2c2c2c;
  max-width: 100%;
}

p{
  margin-bottom: 1em;
}

.push-down{
  min-height: 100vh;
}

h1{
  font-family:  'Poppins', sans-serif;
  font-weight: bold;
  font-size: 14pt;
  color: #009FE3;
}
h2{
  display: inline-block;
  
}

.title-line{
  width: 20em;
}

.side-note{
  font-size: 10pt;
  margin-bottom: 1em;
}

input{
  color: #2c2c2c;
}

.name-input{
  width: 100%;
  padding: 1em;
  margin: 1em 0;
  outline: 0;
  transition: all .2s ease;
  border-width: 1px 1px 1px 1px;
  border-color: #e2e2e2;
  border-radius: 4px;
  border-style: solid;
  background-color: #ffffff;
  font-size: 14px;
  height: 3em;
  box-sizing: border-box;
  -webkit-box-sizing:border-box;
  -moz-box-sizing: border-box;
}

.checkbox-input{
  padding: 1em;
  margin: 1em 0;
  margin-right: 0.5em;
}

.radio-input{
  padding: 1em;
  margin: 1em;
}

button{
  float: left;
  width: auto;
  padding: 0 1em;
  margin: 1em 0;
  transition: all .2s ease;
  border-radius: 2em;
  border-style: none;
  background-color: #009FE3;
  font-family:  'Poppins', sans-serif;
  font-weight: bold;
  font-size: 14pt;
  color: #ffffff;
  height: 50px;
  text-transform: uppercase;
  transition: all .3s;
}

button:disabled,button:disabled:hover{
  background-color:#9c9ea1;
  color: #666666;
}

button:hover{
  background-color:#3d66ae;
}

li {
  list-style: inside;
}


.add-button{
  float: left;
  width: auto;
  padding: 0 0.5em;
  margin: 0.5em 0;
  transition: all .2s ease;
  border-radius: none;
  border-style: none;
  background: none;
  font-family:  'Poppins', sans-serif;
  font-weight: bold;
  font-size: 12pt;
  color: #009FE3;
  height: 30px;
  text-transform: uppercase;
  transition: all .3s;
}

.add-button:disabled,button:disabled:hover{
  background-color:#9c9ea1;
  color: #666666;
}

.add-button:hover{
  background-color:#3d66ae;
}

.neighbour_title_div{
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.neighbour_title{
  display: inline-flex;
  float: left;
  width: auto;
  padding: 0 0.5em;
  margin: 0.5em 0;
  font-weight: bold;
  font-size: 12pt;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
}

.neighbour-box{
  display: inline-block;
  border-radius: 1em;
  border-width: 1px;
  border-style: solid;
  border-color: #dadada;
  padding: 1em;
  margin-bottom: 1em;
}

.errorMsg{
  color: #f00;
  font-size: 10pt;
  margin: auto;
  padding: 0;
  position: static;
  top: 1em;
  z-index: 1;
}

.form-container {
  position: relative;
  display:inline-block;
  left: 20em;
  top:10em;
  min-width: 20em;
  padding: 2em;
  margin-bottom: 20em;
  background-color: #ffffff;
  border-radius: 8px 8px 8px 8px;
  overflow: hidden;
  box-shadow: 0px 10px 14px 9px rgb(0 0 0 / 10%);
}

.form-column{
  width: 20em;
  float: left;
  padding-right: 2em;
}

body{
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  background-image: url(/static/media/Background-1200x800.aa1b3ffa.jpg);
}

html{
  width: 100%;
}

@media screen and (max-width: 1500px) {
  .form-container {
    width: 100vw;
    left: 0;
    min-width: 0;
    padding: 2em;
  }
}

@media screen and (max-width: 1000px) {
  .form-container {
    position: relative;
    display:inline-block;
    width: 100vw;
    left: 0;
    top:0;
    min-width: 0;
    padding: 2em;
    margin-bottom: 20em;
    background-color: #ffffff;
    border-radius: 8px 8px 8px 8px;
    overflow: hidden;
    box-shadow: 0px 10px 14px 9px rgb(0 0 0 / 10%);
    box-sizing: border-box;
  }
  .form-column{
    width: 100%;
    float: left;
    padding-right: 0;
  }
}
form{
    display: block;
}

.input-field{
    position: relative;
}
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: auto;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    margin: 8px;
    border: 8px solid #009FE3;
    border-radius: 50%;
    -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #009FE3 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
  }
  @-webkit-keyframes lds-ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes lds-ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
.sticky-bottom{
    position:-webkit-sticky;
    box-sizing: border-box;
    width: 100%;
    position:sticky;
    bottom:0;
    left: 0;
    padding: 2em;
    background-color: #ffffff;
    overflow: hidden;
    box-shadow: 10px 10px 14px 9px rgb(0 0 0 / 20%);
}

.footer{
    position: relative;
    left:20em;
    height: 50px;
}

.footer-buttons{
    float:left;
    width: 100%;
    padding: 0 1em;
    margin: 0;
}

.price{
    float:left;
    width: auto;
    padding: 0 1em;
    margin: 1em 0;
}
.dimmer{
    background: #000;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -50;
}
.modal{
    z-index: 200;
    margin: 0;
    opacity: 1;
    padding: 1em 2em 0;
    background-color: #ffffff;
    border-radius: 8px 8px 8px 8px;
    box-shadow: 0px 10px 14px 9px rgb(0 0 0 / 10%);
    overflow: hidden;
    max-width: 80vh;
}

.modal-content{
    max-height: 80vh;
    overflow: auto;
}

.save-button{
    float:left;
    width: auto;
    padding: 0 1em;
    margin: 1em 0;
    margin-right: 1em;
    transition: all .2s ease;
    border-radius: 2em;
    border-style: none;
    background-color: #fff;
    font-family:  'Poppins', sans-serif;
    font-weight: bold;
    font-size: 14pt;
    color: #009FE3;
    height: 50px;
    text-transform: uppercase;
    transition: all .3s;
}

.popup-button{
    float: left;
    width: auto;
    padding: 0;
    margin: 0.2em 0;
    margin-right: 1em;
    transition: all .2s ease;
    outline: none;
    border-radius: none;
    border-style: none;
    background: none;
    font-family:  'Poppins', sans-serif;
    font-weight: bold;
    font-size: 9pt;
    color: #009FE3;
    height: 30px;
    text-transform: uppercase;
    transition: all .3s;
  }
  
.popup-button:hover{
    background: none;
}

@media screen and (max-width: 1500px) {
    .footer{
        box-sizing: border-box;
        position: relative;
        left:0;
        width: 100%;
    }
}
  
@media screen and (max-width: 1000px) {
    .footer{
        box-sizing: border-box;
        position: relative;
        left:0;
        width: 100%;
    }
    .sticky-bottom{
        padding: 1em;
    }
    button, .save-button{
        margin: auto;
        margin-bottom: 0.5em;
        height: 2em;
        width: 100%;
    } 
    .save-button:hover{
        background-color: #fff;
    } 
    .price{
        margin:0;
    } 
    .modal{
        margin: 1em;
    }
}
.header{
    padding: 1em;
    position: fixed;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media screen and (max-width: 1000px) {
    .header{
      position: unset;
    }
  }
img{
    max-width: 90%;
}
