.sticky-bottom{
    position:-webkit-sticky;
    box-sizing: border-box;
    width: 100%;
    position:sticky;
    bottom:0;
    left: 0;
    padding: 2em;
    background-color: #ffffff;
    overflow: hidden;
    box-shadow: 10px 10px 14px 9px rgb(0 0 0 / 20%);
}

.footer{
    position: relative;
    left:20em;
    height: 50px;
}

.footer-buttons{
    float:left;
    width: 100%;
    padding: 0 1em;
    margin: 0;
}

.price{
    float:left;
    width: auto;
    padding: 0 1em;
    margin: 1em 0;
}
.dimmer{
    background: #000;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -50;
}
.modal{
    z-index: 200;
    margin: 0;
    opacity: 1;
    padding: 1em 2em 0;
    background-color: #ffffff;
    border-radius: 8px 8px 8px 8px;
    box-shadow: 0px 10px 14px 9px rgb(0 0 0 / 10%);
    overflow: hidden;
    max-width: 80vh;
}

.modal-content{
    max-height: 80vh;
    overflow: auto;
}

.save-button{
    float:left;
    width: auto;
    padding: 0 1em;
    margin: 1em 0;
    margin-right: 1em;
    transition: all .2s ease;
    border-radius: 2em;
    border-style: none;
    background-color: #fff;
    font-family:  'Poppins', sans-serif;
    font-weight: bold;
    font-size: 14pt;
    color: #009FE3;
    height: 50px;
    text-transform: uppercase;
    transition: all .3s;
}

.popup-button{
    float: left;
    width: auto;
    padding: 0;
    margin: 0.2em 0;
    margin-right: 1em;
    transition: all .2s ease;
    outline: none;
    border-radius: none;
    border-style: none;
    background: none;
    font-family:  'Poppins', sans-serif;
    font-weight: bold;
    font-size: 9pt;
    color: #009FE3;
    height: 30px;
    text-transform: uppercase;
    transition: all .3s;
  }
  
.popup-button:hover{
    background: none;
}

@media screen and (max-width: 1500px) {
    .footer{
        box-sizing: border-box;
        position: relative;
        left:0;
        width: 100%;
    }
}
  
@media screen and (max-width: 1000px) {
    .footer{
        box-sizing: border-box;
        position: relative;
        left:0;
        width: 100%;
    }
    .sticky-bottom{
        padding: 1em;
    }
    button, .save-button{
        margin: auto;
        margin-bottom: 0.5em;
        height: 2em;
        width: 100%;
    } 
    .save-button:hover{
        background-color: #fff;
    } 
    .price{
        margin:0;
    } 
    .modal{
        margin: 1em;
    }
}